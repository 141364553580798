<template>
  <div class="w-100 d-flex align-items-center justify-content-between">
    <b-form-group class="p-0" :label="translations.filters.claim_id" style="max-width: 550px; min-width: 250px;">
      <b-form-tags
      v-model="filter_set.claim_keys"
      tag-variant="info"
      separator=" "
      no-add-on-enter
      :disabled="loading"
      placeholder=""/>
    </b-form-group>
    <b-button
      data-testid="btn-search"
      class="mt-2"
      variant="primary"
      :disabled="loading"
      @click="applyFilters">
      {{ translations.filters.btn_search }}
    </b-button>
  </div>
</template>

<script>
import translations from '@/translations';

export default {
  name: 'ClaimFollowUpTasksFilters',
  data() {
    return {
      loading: false,
      translations: translations.finance_operations.claims_follow_up_tasks,
      filter_set: { claim_keys: [] },
    };
  },
  methods: {
    recoverFiltersFromURL(filters) {
      this.filter_set = { ...filters };
    },
    applyFilters() {
      this.loading = true;
      this.$emit('apply-filters', this.filter_set);
      this.loading = false;
    },
  },
};
</script>
