<template>
  <claim-tasks-layout>
    <div class="d-flex align-items-center">
      <filters
      data-testid="filters"
      ref="filters"
      @apply-filters="applyFilters"/>
    </div>
    <div class="w-100">
      <financial-list
        v-model="currentPage"
        :header-data="TABLE_HEADERS"
        :events="TABLE_EVENTS"
        :data="list"
        :disabled="loading"
        :items-per-page="itemsPerPage"
        :total="total"
        :no-items-message="translations.list.empty_table"
        itemRefKey="id"
        @page-changed="fetchList"
        @row-details="handleDetails"/>
    </div>
  </claim-tasks-layout>
</template>

<script>
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import ClaimTasksLayout from '@/components/FinanceOperations/ClaimTasksLayout.vue';
import Filters from '@/components/FinanceOperations/ClaimFollowUpTasks/filters.vue';
import service from '@/services/finance-service';
import translations from '@/translations';
import { CLAIM_FOLLOW_UP_TASK_STATUS } from '@/constants/financeOperations';
import { parseResponseError } from '@/http/parsers/parse_response';
import { formatDate, formatValue } from '@/helpers/finance';

export default {
  name: 'ClaimFollowUpTasksList',
  components: {
    FinancialList,
    ClaimTasksLayout,
    Filters,
  },
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.list.claim_id, 'claim.unique_key'),
      associateHeaderDataItem(this.translations.list.client_name, 'claim_account.client_name'),
      associateHeaderDataItem(this.translations.list.payer_name, 'claim_account.payer_name'),
      associateHeaderDataItem(this.translations.list.assignee, 'claim_account.assignee'),
      associateHeaderDataItem(this.translations.list.claim_submitted_date, 'submitted_date'),
      associateHeaderDataItem(this.translations.list.billed_amount, 'total_amount'),
      associateHeaderDataItem(this.translations.list.paid_amount, 'paid_amount'),
      associateHeaderDataItem(this.translations.list.task_status, 'status_label'),
      associateHeaderDataItem(this.translations.list.next_action, 'next_action'),
      associateHeaderDataItem(this.translations.list.next_action_due_date, 'next_action_due_date'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: this.translations.list.view_details,
        icon: 'eye',
      },
    };
  },
  data() {
    return {
      translations: translations.finance_operations.claims_follow_up_tasks,
      loading: true,
      list: [],
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      appliedFilters: {},
    };
  },
  async beforeMount() {
    const page = this.$route.query?.page ? Number(this.$route.query.page) : 1;
    this.currentPage = page;
    this.total = page * this.itemsPerPage;
  },
  mounted() {
    this.restoreFiltersFromQuery();
  },
  methods: {
    restoreFiltersFromQuery() {
      const { query } = this.$route;
      const filters = { claim_keys: query?.claim_keys || [] };
      const page = query?.page ? Number(query.page) : 1;

      if (typeof filters.claim_keys === 'string') {
        filters.claim_keys = [ filters.claim_keys ];
      }

      this.$refs.filters.recoverFiltersFromURL(filters);
      this.applyFilters(filters, page);
    },
    async applyFilters(filters, page = 1) {
      this.currentPage = page;
      this.appliedFilters = {};

      if (filters.claim_keys.length) {
        this.appliedFilters.claim_keys = filters.claim_keys;
      }

      await this.fetchList(this.currentPage);
    },
    async fetchList(page) {
      this.loading = true;
      this.currentPage = page;
      this.setParamsToQuery();

      const offset = (page - 1) * this.itemsPerPage;

      try {
        const { claim_follow_up_tasks: claimFollowUpTasks, total } = await service.getClaimFollowUpTasks({
          ...this.appliedFilters,
          order_by: 'submitted_date_client_name',
          offset,
          limit: this.itemsPerPage,
        });

        this.list = claimFollowUpTasks.map(claimFUT => ({
          ...claimFUT,
          submitted_date: formatDate(claimFUT.claim.submitted_date),
          total_amount: formatValue(claimFUT.claim.value),
          paid_amount: formatValue(claimFUT.claim.paid_value),
          next_action_due_date: formatDate(claimFUT.next_action_due_date),
          status_label: CLAIM_FOLLOW_UP_TASK_STATUS[claimFUT.status] ? CLAIM_FOLLOW_UP_TASK_STATUS[claimFUT.status] : claimFUT.status,
        }));
        this.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_list}: ${parseResponseError(err)}`);
      }
      this.loading = false;
    },
    setParamsToQuery() {
      const query = {
        ...this.appliedFilters,
        page: this.currentPage > 1 ? this.currentPage : undefined,
      };

      const newQuery = new URLSearchParams(query).toString();
      const currentQuery = new URLSearchParams(this.$route.query).toString();

      if (newQuery !== currentQuery) {
        this.$router.replace({ query });
      }
    },
    handleDetails(id) {
      this.$router.push(`/finance-operations/claim-follow-up-tasks/${id}`);
    },
  },
};
</script>
